import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import Layout from "../../../components/shared-layout";
import useStores from "../../../hooks/use-stores";
import VmTable from "../../../components/shared-table";
import VmButton from "../../../components/shared-button";
import { ApiDataListType, ApiDataResponseListLengthCheck, CRUDOperation, GetCollegeId } from "../../../constants/options";
import Breadcrumb from "../../../components/shared-breadcrumb";
import VmModal from "../../../components/shared-modal";
import { t } from "i18next";
import ContentLayout from "../../../components/shared-content-layout";
import { TD_FIRST, TD_LAST, TD_NORMAL } from "../../../constants/style";

const QuestionTypeIndexPage = observer(() => {
  const { rootStore, questionStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Determine is update or create
  const [targetType, setTargetType] = useState<any>();
  const [typeCode, setTypeCode] = useState<string>("");
  const [typeDescription, setTypeDescription] = useState<string>("");

  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.QUESTION_TYPE) == 0) {
      questionStore.getQuestionType();
    }
  }, []);

  const onActionQuestionType = () => {
    let req: any = {
      id: 0,
      typeCode,
      typeDescription,
      collegeId: GetCollegeId(),
    }
    if (targetType) {
      req.id = targetType.id;
      questionStore.updateQuestionType(req).then(() => {
        rootStore.notify(t('QUESTION_TYPE') + t('UPDATED_B'), 'success');
        questionStore.getQuestionType();
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      questionStore.createQuestionType(req).then(() => {
        rootStore.notify(t('QUESTION_TYPE') + t('CREATED_B'), 'success');
        questionStore.getQuestionType();
        handleCloseModal();
      }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
    }
  }

  const handleShowModal = (id?: number) => {
    if (id) {
      let target = questionStore.questionTypeList.find(qt => qt.id == id);
      setTargetType(target);
      setTypeCode(target.typeCode);
      setTypeDescription(target.typeDescription);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setTargetType(null);
    setTypeCode("");
    setTypeDescription("");
  }

  const handleDeleteModal = (id: number) => {
    let target = questionStore.questionTypeList.find(qt => qt.id == id);
    setTargetType(target);
    setShowDeleteModal(true);
  }

  return (
    <ContentLayout
      pageName={t('QUESTION_TYPE')}
      pageHeading={t('QUESTION_TYPE')}
      breadCrumb={[
        { label: t('QUESTION') + t('SETTING_B'), to: "/question/overview" },
        { label: t('TYPE'), to: "/question/type" }
      ]}
      buttonLabel={t('ADD_A') + t('QUESTION_TYPE')}
      onClickAction={() => handleShowModal()}
    >
      <VmTable
        loading={questionStore.loading}
        page={0}
        thead={["ID", t('QUESTION_TYPE') + t('CODE_B'), t('QUESTION_TYPE') + t('DESCRIPTION_B'), t('ACTION')]}
        tbody={questionStore.questionTypeList.length > 0 &&
          questionStore.questionTypeList.map((type, index) => (
            <>
              <Box marginY={1} />
              <tr key={`type_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{type.typeCode}</td>
                <td className={TD_NORMAL}>{type.typeDescription}</td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowModal(type.id)}
                  >
                    {t('EDIT')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteModal(type.id)}
                  >
                    {t('DELETE')}
                  </Button>
                </td>
              </tr>
              <Box marginY={1} />
            </>
          ))}
      />

      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        title={targetType ? t('UPDATE_A') + t('QUESTION_TYPE') : t('ADD_A') + t('QUESTION_TYPE')}
        buttonLabel={targetType ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetType ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionQuestionType}
        disabledCondition={typeCode === "" || typeDescription === ""}
      >
        <TextField
          value={typeCode}
          label={t('QUESTION_TYPE') + t('CODE_B')}
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          onChange={(value) => setTypeCode(value.target.value)}
        />
        <TextField
          value={typeDescription}
          label={t('DESCRIPTION')}
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          rows={4}
          multiline
          onChange={(value) => setTypeDescription(value.target.value)}
        />
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => {
          questionStore.deleteQuestionType(targetType.id).then(() => {
            rootStore.notify(t('QUESTION_TYPE') + t('DELETED_B'), 'success')
            handleCloseModal();
            questionStore.getQuestionType();
          }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))
        }}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}?</Typography>
        <Typography color="red" variant="caption">
          {t('DELETE_INFO_QUESTION_TYPE')}
        </Typography>
      </VmModal>
    </ContentLayout>
  )
});

export default QuestionTypeIndexPage;
